.home {}
.highlight-product--tabs {
  flex-wrap: nowrap;
  overflow-x: auto;
  overscroll-behavior: contain;
  border: 0;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  scrollbar-width: thin;
  scrollbar-color: $light-gray $body-bg;
  @include media-breakpoint-down(lg) {
    &::-webkit-scrollbar {
        height: 11px; }
    &::-webkit-scrollbar-track {
      margin-left: 12px;
      margin-right: 12px; }
    &::-webkit-scrollbar {
      height: 11px; }
    &::-webkit-scrollbar-track {
      background: $body-bg; }
    &::-webkit-scrollbar-thumb {
      background-color: $light-gray;
      border-radius: 6px;
      border: 3px solid $body-bg; } }
  li.nav-item {
    flex: 0 0 auto; } }

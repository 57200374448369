.fileeditor {
  &__custom-file-upload-input {
    display: none; }
  &__custom-file-upload-label {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer; }
  .laminate-field {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    &:hover {
      opacity: 0.8; }
    &--icon-container {
      position: relative;
      cursor: pointer;
      border-style: solid;
      border-width: 2px;
      border-color: transparent;
      -webkit-transition: background-color 0.75s ease, border-color 0.3s ease /* Safari */;
      transition: background-color 0.75s ease, border-color 0.3s ease;
      height: 80px;
      overflow: hidden;
      &.selected {
        border-color: #7fcf20; } }
    &--icon {
      position: relative;
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      width: 60%;
      &.laminate-ad-hoc--glossy, &.laminate-ad-hoc--matt {
        margin: 5px auto; } }
    &--checkmark {
      position: absolute;
      bottom: 0px;
      right: 0px;
      height: 20px;
      width: 30px;
      border: 0; }
    &--title {}
    margin-top: 4px;
    font-size: 12px; }


  .cutting-field {
    margin-top: 5px;
    margin-bottom: 5px;
    &-details {
      &-title {}
      &-subtitle {
        cursor: pointer;
        font-weight: normal; } }
    &-label {
      -webkit-transition: background-color 0.75s ease, border-color 0.3s ease /* Safari */;
      transition: background-color 0.75s ease, border-color 0.3s ease;
      cursor: pointer;
      border-style: solid;
      border-width: 2px;
      border-color: transparent;
      margin-left: 10px;
      margin-right: 10px;
      &:hover {
        opacity: 0.8; }
      &.checked {
        border-color: #7fcf20; } }
    input {
      display: none; }
    &-icon-container {
      position: relative;
      padding-left : 0;
      cursor: pointer; }
    &-icon {
      height: 70px;
      cursor: pointer;
      width: 100%;
      position: relative;
      padding: 5px; }
    &-checkmark {
      position: absolute;
      bottom: 5px;
      right: 0px;
      height: 20px;
      width: 30px;
      border: 0; } }

  .filecheck-field {
    margin-top: 5px;
    margin-bottom: 5px;
    &-details {
      &.disabled {
        cursor: not-allowed; }
      &-title {}
      &-subtitle {
        font-weight: normal; } }
    &-label {
      -webkit-transition: background-color 0.75s ease, border-color 0.3s ease /* Safari */;
      transition: background-color 0.75s ease, border-color 0.3s ease;
      cursor: pointer;
      width: 100%;
      border-style: solid;
      border-width: 2px;
      border-color: transparent;
      margin-right: 5px;
      margin-left: 5px;
      &:hover {
        opacity: 0.8; }
      &.checked {
        border-color: #7fcf20; }
      &.disabled {
        cursor: not-allowed;
        opacity: 0.3; } }
    input {
      display: none; }
    &-icon-container {
      position: relative;
      padding-left : 0;
      cursor: pointer;
      &.disabled {
        cursor: not-allowed; } }
    &-icon {
      // margin-left: 15%
      height: 80px;
      cursor: pointer;
      position: relative;
      padding: 5px; }
    &-checkmark {
      position: absolute;
      bottom: 5px;
      right: 0px;
      height: 20px;
      width: 30px;
      border: 0; }
    &-nofilecheck {
      &-radio {
        margin: 20px;
        margin-left: 12%; }
      &-label {
        cursor: pointer;
        width: 100%; } } }
  .editortype-field {
    &-value {
      background: $white;
      cursor: pointer;
      border-radius: 5px;
      box-shadow: $box-shadow-sm;
      img {
        margin-right: 5px; }
      &:hover {
        background: $gray-100; } } }
  .fileupload {
    width: 100%;
    background-color: $white;
    padding: 15px;
    background-image: url('../../assets/images/canvas-background.png');
    &-dropzone {
      width: 100%;
      min-height: 300px;
      text-align: center;
      &-button {
        width: 100%;
        padding: 10%;
        align-items: center;
        justify-content: center; }

      &--disable-preview {
        min-height: 0px; }
      &-preview {
        width: 100%;
        &--rectangular {
          background-color: #fff; } }
      &--active {
        background-color: rgba($success, .6); }
      &--reject {
        background-color: rgba($danger, .6); } }
    &-filetypes {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap; }
    &-filetype {
        padding: 4px; } } }

.sizeselect-list {
  list-style: none;
  position: relative;
  padding-left: 0;
  li {
    cursor: pointer;
    padding-left: 1.25em;
    &.selected:before {
      position: absolute;
      left: 0;
      content: '✓';
      color: $success;
      font-weight: bold; }
    &:hover {
      text-decoration: unterline;
      background-color: $gray-200; } } }

.file-size-modal, .vectorize-file-modal {
  text-align: center;
  &__icon {
    font-size: 2rem !important;
    margin: 5px;
    color: $primary; }
  &__col {
    padding-bottom: 15px;
    padding-top: 5px;
    &--title {} } }
.productselectionmodal {
  &__tabs {
    .nav-item {
      cursor: pointer; }
    &.nav-pills {
      .nav-link.active {
        color: $white; } } }
  &__body {
    min-height: 60vh;
    .editortypefield__option {
      &:hover {
        cursor: pointer;
        background: $gray-300; } } }
  &__texteditorlink {
    &:hover {
      background-color: $gray-300; }
    a {
      color: $gray-900;
      &:hover {
        color: $gray-900;
        text-decoration: none; } } } }

.amount-select {
  .css-1uccc91-singleValue {
    .not-in-preview {
      display: none; } } }
